import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Split from '../components/Split';

import WaterSVG from '../icons/Water.svg';
import WeedsSVG from '../icons/Weeds.svg';
import SoilSVG from '../icons/Soil.svg';
import RevegetationSVG from '../icons/Revegetation.svg';
import WildlifeSVG from '../icons/Wildlife.svg';
import SupplierSVG from '../icons/Supplier.svg';
import PackagingSVG from '../icons/Packaging.svg';
import NewsletterSVG from '../icons/Newsletter.svg';
import MachinerySVG from '../icons/Machinery.svg';

import styles from './sustainability.module.css';

const SustainabilityPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Environmental sustainability in wine production">
        <p>
          I have long been concerned about the nature and the pace of
          environmental change as it relates to the survival of species
          (including our own). Some of these changes include increasing
          temperature, altered rainfall patterns, altered abundance and distribution
          of species, new diseases emerging and the ever present threat of various
          pollutants.
        </p>
        <Split>
          <Img fluid={data.eagleImage.childImageSharp.fluid} />
          <div>
            <p>
              The actions we take today can have a lasting impact on the environment
              and the time has come for our industry to get on board. We need to
              limit our environmental impact so that the natural world can at least
              be sustained in its current form. This will assist the thousands of species
              that share the planet with us to sustain their populations and their way of
              life.
            </p>
            <p>
              In the long term, being both selfish and practical, we must strive to
              sustain ourselves as individuals and as a species. To guarantee our
              future as a species we must be able to grow food, fibre and beverage
              for generations to come without harming our environment’s ability to
              sustain us.
            </p>
          </div>
        </Split>
        <p>
          In this future that we all hope to be a part of, we must do things
          quite differently to the way we have done them in the past. This
          implies that certain practices must be abandoned and others must be
          adopted. In the wine industry we are facing environmental challenges
          throughout the entire production timeline from grape to table. In the
          vineyard and winery there is much to be done and consumers across the board
          are quite rightly asking questions about the methods being used in the
          production of their favourite products.
        </p>
        <p>
          We would like to share with you our sustainability charter below and
          invite you to join us on our journey, engage us with your thoughts and
          suggestions as we improve our practices and transition from being part
          of the problem to part of the solution.
        </p>
      </Section>
      <Section heading="Our sustainability charter">
        <div className={styles.charter}>
          <div className={styles.charterItem}>
            <WaterSVG className={styles.icon} />
            <h3>Water</h3>
            <p>
              To become self-sufficient in water, to treat our own waste water and
              to discharge only pure, safe water beyond our boundary.  </p>
          </div>
          <div className={styles.charterItem}>
            <WeedsSVG className={styles.icon} />
            <h3>Weeds</h3>
            <p>
              To manage weeds without chemical weedicides and to promote a diverse
              ground cover with its innate ability to remain healthy.
            </p>
          </div>
          <div className={styles.charterItem}>
            <SoilSVG className={styles.icon} />
            <h3>Soil health</h3>
            <p>
              To promote soil micro-organisms, worms and arthropods through organic
              inputs including ground cover, compost and mulch.
            </p>
          </div>
          <div className={styles.charterItem}>
            <RevegetationSVG className={styles.icon} />
            <h3>Revegetation</h3>
            <p>
              To create and maintain significant areas of bushland, replanted
              native communities and wild grassland in the interests of habitat for
              all creatures.
            </p>
          </div>
          <div className={styles.charterItem}>
            <WildlifeSVG className={styles.icon} />
            <h3>Wildlife</h3>
            <p>
              To embrace our natural wildlife and adapt to living in harmony with
              predators and prey species. Some species test our patience more than
              others!
            </p>
          </div>
          <div className={styles.charterItem}>
            <SupplierSVG className={styles.icon} />
            <h3>Supplier relationships</h3>
            <p>
              To identify and engage suppliers that are prepared to reduce
              packaging, reduce unnecessary plastic and eliminate materials that
              are not recyclable.
            </p>
          </div>
          <div className={styles.charterItem}>
            <PackagingSVG className={styles.icon} />
            <h3>Outbound packaging</h3>
            <p>
              To improve the packaging components of our products so that our
              clients are provided with the most sustainable options for disposal
              and environmental impact.
            </p>
          </div>
          <div className={styles.charterItem}>
            <NewsletterSVG className={styles.icon} />
            <h3>Newsletter</h3>
            <p>
              To move away from printed material, even though it is recycled, to
              electronic means of distribution thus reducing the need for printed
              content.
            </p>
          </div>
          <div className={styles.charterItem}>
            <MachinerySVG className={styles.icon} />
            <h3>Machinery</h3>
            <p>
              To invest in equipment that promotes and supports our environmental
              objectives by enabling practices that are ecologically friendly and
              minimally damaging.
            </p>
          </div>
        </div>
      </Section>
      <Pagination
        forwardLink="/global"
        backLink="/cabernet"
      />
    </Layout>
  );
};

SustainabilityPage.propTypes = {
  data: PropTypes.shape({
    eagleImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    eagleImage: file(relativePath: { eq: "eagle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default SustainabilityPage;
